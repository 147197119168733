import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentSection } from "../components/ContentSection"
import TopSubNav from "../components/TopSubNav"
import styled from "styled-components"
import { format } from "date-fns"
import CallToActionContact from "../components/CallToActionContact"
import InsightsForm from "../components/InsightsForm"
import RelatedArticles from "../components/RelatedArticles"

export const query = graphql`
  query InsightsQuery($uid: String) {
    prismic {
      allInsights(uid: $uid) {
        edges {
          node {
            seo_h1
            seo_page_description
            seo_page_title
            feature_image
            publication_date
            short_description
            title
            content
            call_to_action
            resource_type
            _meta {
              uid
            }
            resource_file {
              _linkType
              ... on PRISMIC__FileLink {
                _linkType
                url
                name
                size
              }
            }
          }
        }
      }
      allInsight_privacy_notices {
        edges {
          node {
            notice_text
          }
        }
      }
    }
  }
`

const ArticleSection = styled(ContentSection)`
  .inner-container {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      display: block;

      .left-col {
        margin-right: 0;
        width: 100%;
      }

      .right-col {
        margin: 2rem 0;
        width: 100%;
      }
    }
  }

  h1,
  h2 {
    font-size: 2rem;
  }

  .left-col {
    flex: 2.5;
    margin-right: 2rem;
  }

  .right-col {
    flex: 1.5;
  }

  .meta {
    display: flex;
    align-items: center;
  }

  .date {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    font-size: 12px;
  }

  .tag {
    background-color: #8ca3b5;
    color: white;
    padding: 3px 12px;
    border-radius: 20px;
    font-size: 12px;
  }

  .main-content {
    h3 {
      font-size: 2rem;
      margin-top: 0;
      font-weight: 700;
      font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    }
  }

  .feature-image-container {
    position: relative;

    .feature-title-meta-container {
      position: absolute;
      bottom: 0;
      padding: 1rem;

      h1,
      h2,
      .date {
        color: #fff;
      }
    }
  }
`

const FeatureImage = styled("div")`
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
`

export default ({ data }) => {
  const doc = data.prismic.allInsights.edges.slice(0, 1).pop()
  if (!doc) return null

  const resourceUrl =
    doc.node.resource_file && doc.node.resource_file.url
      ? doc.node.resource_file.url
      : undefined

  const resourceName =
    doc.node.resource_file && doc.node.resource_file.name
      ? doc.node.resource_file.name
      : undefined

  const formattedDate = !doc.node.publication_date
    ? undefined
    : new Date(doc.node.publication_date)

  const featureImage =
    !doc.node.feature_image || !doc.node.feature_image.url
      ? undefined
      : doc.node.feature_image.url

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.title &&
      doc.node.title[0] &&
      doc.node.title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    (doc.node &&
      doc.node.short_description &&
      doc.node.short_description[0] &&
      doc.node.short_description[0].text) ||
    ""

  const privacyNoticeDoc = data.prismic.allInsight_privacy_notices.edges
    .slice(0, 1)
    .pop()
  const privacyNoticeText = privacyNoticeDoc.node.notice_text

  const callToAction =
    (doc.node &&
      doc.node.call_to_action &&
      doc.node.call_to_action[0] &&
      doc.node.call_to_action[0].text) ||
    "Download Resource"

  const resourceType = (doc.node && doc.node.resource_type) || ""

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={featureImage}
      />
      <TopSubNav
        title="Back to Insights"
        titleLink="/resources"
        imageUrl={featureImage}
      />
      <ArticleSection>
        <div className="inner-container">
          <div className="left-col">
            {featureImage && (
              <div className="feature-image-container">
                <FeatureImage imageUrl={featureImage}></FeatureImage>
                <div className="feature-title-meta-container">
                  {doc.node.seo_h1 &&
                  doc.node.seo_h1[0] &&
                  doc.node.seo_h1[0].text
                    ? RichText.render(doc.node.seo_h1)
                    : RichText.render(doc.node.title)}
                  <div className="meta">
                    {formattedDate && (
                      <span className="date">
                        {format(formattedDate, "MMMM dd, yyyy")}
                      </span>
                    )}
                    {resourceType && (
                      <span
                        className="tag"
                        style={{ backgroundColor: "#0099CB" }}
                      >
                        {resourceType}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!featureImage && (
              <>
                {doc.node.seo_h1 &&
                doc.node.seo_h1[0] &&
                doc.node.seo_h1[0].text
                  ? RichText.render(doc.node.seo_h1)
                  : RichText.render(doc.node.title)}
                <div className="meta">
                  {formattedDate && (
                    <span className="date">
                      {format(formattedDate, "MMMM dd, yyyy")}
                    </span>
                  )}
                </div>
              </>
            )}
            <div className="main-content">
              {RichText.render(doc.node.content)}
            </div>
          </div>
          <div className="right-col">
            {resourceUrl ? (
              <InsightsForm
                insightName={seoTitle}
                resourceUrl={resourceUrl}
                resourceName={resourceName}
                noticeText={privacyNoticeText}
                callToAction={callToAction}
              />
            ) : (
              <RelatedArticles currentUid={doc.node._meta.uid} />
            )}
          </div>
        </div>
      </ArticleSection>
      <CallToActionContact />
    </Layout>
  )
}
