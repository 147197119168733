import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ActionButton from "./ActionButton"
import { TextField } from "@material-ui/core"
import { RichText } from "prismic-reactjs"

const SESSION_KEY = "CRG_INSIGHTS_FORM_DATA"

const Container = styled("div")`
  .inner-container {
    width: 100%;
    padding: 1rem;
    background: #fcfcfc;
    border-radius: 3px;
    flex-direction: column;
  }

  .insight-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .insight-form-content {
    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0.5rem 0;

      .form-field {
        flex: 1;
        margin-right: 1rem;
      }

      .form-field:last-child {
        margin-right: 0;
      }
    }

    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSelect.MuiOutlinedInput-inputSelect.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
      white-space: normal;
    }
  }

  .insight-form-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .status-messages {
    position: relative;
  }

  .notice-text {
    p {
      font-size: 12px;
    }
  }
`

function isEmailValid(email) {
  if (!email) return true
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const result = re.test(String(email).toLowerCase())
  return result
}

const InsightsForm = ({
  resourceUrl,
  resourceName,
  noticeText,
  insightName,
  callToAction,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [success, setSuccess] = useState(undefined)
  const [formData, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    company: "",
  })

  const { firstname, lastname, email, company } = formData

  const validEmail = isEmailValid(email)

  async function submitForm(e) {
    setIsSubmitting(true)
    e.preventDefault()
    const body = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      company: formData.company,
      resource: insightName,
    }
    try {
      await fetch(`/.netlify/functions/submit-insight`, {
        method: "POST",
        body: JSON.stringify(body),
      })
      console.log("Form successfully submitted")
      downloadFile()
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(formData))
      setIsSubmitting(false)
      setSuccess(true)
      setForm({
        firstname: "",
        lastname: "",
        email: "",
        company: "",
      })
    } catch (err) {
      console.log("Error submitting form", err)
      setIsSubmitting(false)
      setSuccess(false)
    }
  }

  function downloadFile() {
    fetch(resourceUrl, {
      method: "GET",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
      },
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = url
        a.download = resourceName
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    const session = sessionStorage.getItem(SESSION_KEY)
    if (session) {
      const data = JSON.parse(session)
      setForm(data)
      setHasSubmitted(true)
    } else {
      setHasSubmitted(false)
    }
  }, [])

  return (
    <Container>
      <div className="inner-container">
        {success !== true && success !== false && (
          <form
            className="insight-form"
            onSubmit={submitForm}
            name="insight"
            method="POST"
          >
            {!hasSubmitted && (
              <div className="insight-form-content">
                <div className="row">
                  <TextField
                    id="first-name"
                    name="firstname"
                    className="form-field"
                    label="Your first name"
                    variant="outlined"
                    margin="dense"
                    value={firstname}
                    onChange={e =>
                      setForm({
                        ...formData,
                        ...{ firstname: e.currentTarget.value },
                      })
                    }
                  />
                </div>
                <div className="row">
                  <TextField
                    id="last-name"
                    name="lastname"
                    className="form-field"
                    label="Your last name"
                    variant="outlined"
                    margin="dense"
                    value={lastname}
                    onChange={e =>
                      setForm({
                        ...formData,
                        ...{ lastname: e.currentTarget.value },
                      })
                    }
                  />
                </div>
                <div className="row">
                  <TextField
                    id="email"
                    name="email"
                    className="form-field"
                    label="Email Address"
                    variant="outlined"
                    margin="dense"
                    error={!validEmail}
                    value={email}
                    onChange={e =>
                      setForm({
                        ...formData,
                        ...{ email: e.currentTarget.value },
                      })
                    }
                  />
                </div>
                <div className="row">
                  <TextField
                    id="company"
                    name="company"
                    className="form-field"
                    label="Your company"
                    variant="outlined"
                    margin="dense"
                    value={company}
                    onChange={e =>
                      setForm({
                        ...formData,
                        ...{ company: e.currentTarget.value },
                      })
                    }
                  />
                </div>
              </div>
            )}

            <div className="insight-form-footer">
              {!hasSubmitted && (
                <div className="notice-text">{RichText.render(noticeText)}</div>
              )}
              <ActionButton
                style={{ width: "100%" }}
                disabled={
                  isSubmitting ||
                  success !== undefined ||
                  !firstname ||
                  !lastname ||
                  !email ||
                  !validEmail ||
                  !company
                }
              >
                {callToAction}
              </ActionButton>
            </div>
          </form>
        )}
        {success === true && (
          <>
            <div style={{ textAlign: "center", padding: "2rem 0 0" }}>
              Thank you for your interest in Carly Rian Group. The requested
              resource will be found in your browser's downloads folder.
            </div>
          </>
        )}
        {success === false && (
          <div className="insight-form-footer">
            <p>
              Looks like we had some trouble sending your request through.
              Please try again, or just end an email directly to{" "}
              <a href="mailto:sales@carlyriangroup.com">
                sales@carlyriangroup.com
              </a>
              . Thanks, and sorry for the inconvenience.
            </p>
            <ActionButton
              style={{ width: "100%" }}
              onClick={() => setSuccess(undefined)}
            >
              Try again
            </ActionButton>
          </div>
        )}
      </div>
    </Container>
  )
}

export default InsightsForm
